.kingdom_cards {
  min-height: 400px !important;
}
.forth.circle .chart-circle-value.circle-style {
  display: none !important;
}
.bars_kingdom {
  cursor: pointer !important;
}

.pemission {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  p {
    font-size: 25px;
    font-weight: 600;
  }
}
